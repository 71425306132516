var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageCard',{attrs:{"pageName":"Create Group","iconClass":['fa', 'fa-users', 'fa-lg']}},[_c('template',{slot:"page_content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-8"},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-input',{attrs:{"id":"name","type":"text","placeholder":"Group Name"},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}})],1)])]),_c('b-form-group',{attrs:{"label":"Group Type","label-for":"group_type_id","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-select',{attrs:{"id":"group_type_id","text-field":"name","value-field":"id","options":_vm.group_types},model:{value:(_vm.group.group_type_id),callback:function ($$v) {_vm.$set(_vm.group, "group_type_id", $$v)},expression:"group.group_type_id"}})],1)])]),_c('b-form-group',{attrs:{"label":"Company Type","label-for":"group_company_type_id","label-cols":3,"horizontal":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('multiselect',{class:[
                  'simple-select group-select highlight-group-title form-control'
                ],attrs:{"options":_vm.companyTypes,"multiple":false,"close-on-select":true,"placeholder":"Select Company Type","group-label":"type","group-values":"companies","group-select":false,"showLabels":false},model:{value:(_vm.group.company_type),callback:function ($$v) {_vm.$set(_vm.group, "company_type", $$v)},expression:"group.company_type"}})],1)])]),_c('br'),_c('b-card',{staticClass:"std-border reports-card"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v("Group Users")])])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-5 pr-0"},[_c('h6',[_vm._v("Available Users")]),_c('b-card',{staticClass:"std-border mb-0 group-sub-card",attrs:{"body-class":"p-1"}},[(!this.users.length)?_c('b-list-group',{staticClass:"text-center"},[_c('b-list-group-item',[_c('i',[_vm._v("No Users found ...")])])],1):_c('b-list-group',_vm._l((_vm.availableUsers),function(user,index){return _c('div',{key:index},[_c('b-list-group-item',{staticClass:"p-1",class:[
                        { selected: user.id === _vm.selectedUser.id },
                        'p-1'
                      ],on:{"click":function($event){return _vm.selectUser(user)}}},[_vm._v(_vm._s(_vm.getUserDetail(user)))])],1)}),0)],1)],1),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"group-control"},[_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-sm-12"},[_c('b-button',{attrs:{"type":"submit","size":"sm","variant":"primary","disabled":!_vm.selectedUser.available},domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;>>&nbsp;&nbsp;')},on:{"click":function($event){return _vm.addUser()}}})],1)]),_c('div',{staticClass:"row text-center pt-1"},[_c('div',{staticClass:"col-sm-12"},[_c('b-button',{attrs:{"type":"reset","size":"sm","variant":"danger","disabled":!_vm.selectedUser.selected},domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;<<&nbsp;&nbsp;')},on:{"click":function($event){return _vm.removeUser()}}})],1)])])]),_c('div',{staticClass:"col-sm-5 pl-0"},[_c('h6',[_vm._v("Selected Users")]),_c('b-card',{staticClass:"std-border mb-0 group-sub-card",attrs:{"body-class":"p-1"}},[(!this.selectedUsers.length)?_c('b-list-group',{staticClass:"text-center"},[_c('b-list-group-item',[_c('i',[_vm._v("No Users selected ...")])])],1):_c('b-list-group',_vm._l((_vm.selectedUsers),function(user,index){return _c('div',{key:index},[_c('b-list-group-item',{staticClass:"p-1",class:[
                        { selected: user.id === _vm.selectedUser.id },
                        'p-1'
                      ],on:{"click":function($event){return _vm.selectUser(user)}}},[_vm._v(_vm._s(_vm.getUserDetail(user)))])],1)}),0)],1)],1)])])],1)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('b-row',{},[_c('b-col',{attrs:{"md":"12"}},[_c('router-link',{staticClass:"btn btn-secondary fw-btn mt-3 mr-3 white-text",attrs:{"tag":"button","to":{ path: '/super_admin/all_groups' }}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"btn btn-primary fw-btn mt-3 mx-3",attrs:{"type":"submit","variant":"primary","disabled":_vm.canCreate},on:{"click":_vm.createGroup}},[_vm._v("Create Group")])],1)],1)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }