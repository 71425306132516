<template>
  <PageCard pageName="Create Group" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <div class="row">
        <div class="col-sm-12 col-lg-8">
          <b-form-group
            label="Name"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Group Name"
                  v-model="group.name"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label="Group Type"
            label-for="group_type_id"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <b-form-select
                  id="group_type_id"
                  v-model="group.group_type_id"
                  text-field="name"
                  value-field="id"
                  :options="group_types"
                ></b-form-select>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            label="Company Type"
            label-for="group_company_type_id"
            :label-cols="3"
            :horizontal="true"
          >
            <div class="row">
              <div class="col-sm-6">
                <multiselect
                  v-model="group.company_type"
                  :options="companyTypes"
                  :multiple="false"
                  :close-on-select="true"
                  placeholder="Select Company Type"
                  group-label="type"
                  group-values="companies"
                  :class="[
                    'simple-select group-select highlight-group-title form-control'
                  ]"
                  :group-select="false"
                  :showLabels="false"
                />
              </div>
            </div>
          </b-form-group>
          <br />
          <b-card class="std-border reports-card">
            <div slot="header">
              <h6 class="mb-0">
                <b>Group Users</b>
              </h6>
            </div>
            <div class="row align-items-center">
              <div class="col-sm-5 pr-0">
                <h6>Available Users</h6>
                <b-card class="std-border mb-0 group-sub-card" body-class="p-1">
                  <b-list-group v-if="!this.users.length" class="text-center">
                    <b-list-group-item>
                      <i>No Users found ...</i>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group v-else>
                    <div v-for="(user, index) in availableUsers" :key="index">
                      <b-list-group-item
                        class="p-1"
                        :class="[
                          { selected: user.id === selectedUser.id },
                          'p-1'
                        ]"
                        @click="selectUser(user)"
                        >{{ getUserDetail(user) }}</b-list-group-item
                      >
                    </div>
                  </b-list-group>
                </b-card>
              </div>
              <div class="col-sm-2">
                <div class="group-control">
                  <div class="row text-center">
                    <div class="col-sm-12">
                      <b-button
                        type="submit"
                        size="sm"
                        variant="primary"
                        :disabled="!selectedUser.available"
                        @click="addUser()"
                        v-html="'&nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;'"
                      ></b-button>
                    </div>
                  </div>
                  <div class="row text-center pt-1">
                    <div class="col-sm-12">
                      <b-button
                        type="reset"
                        size="sm"
                        variant="danger"
                        :disabled="!selectedUser.selected"
                        @click="removeUser()"
                        v-html="'&nbsp;&nbsp;&lt;&lt;&nbsp;&nbsp;'"
                      ></b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5 pl-0">
                <h6>Selected Users</h6>
                <b-card class="std-border mb-0 group-sub-card" body-class="p-1">
                  <b-list-group
                    v-if="!this.selectedUsers.length"
                    class="text-center"
                  >
                    <b-list-group-item>
                      <i>No Users selected ...</i>
                    </b-list-group-item>
                  </b-list-group>
                  <b-list-group v-else>
                    <div v-for="(user, index) in selectedUsers" :key="index">
                      <b-list-group-item
                        class="p-1"
                        :class="[
                          { selected: user.id === selectedUser.id },
                          'p-1'
                        ]"
                        @click="selectUser(user)"
                        >{{ getUserDetail(user) }}</b-list-group-item
                      >
                    </div>
                  </b-list-group>
                </b-card>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div slot="footer">
        <b-row class>
          <b-col md="12">
            <router-link
              tag="button"
              :to="{ path: '/super_admin/all_groups' }"
              class="btn btn-secondary fw-btn mt-3 mr-3 white-text"
              >Cancel</router-link
            >
            <b-button
              type="submit"
              variant="primary"
              class="btn btn-primary fw-btn mt-3 mx-3"
              :disabled="canCreate"
              @click="createGroup"
              >Create Group</b-button
            >
          </b-col>
        </b-row>
      </div>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import router from '@/router'
import PageCard from '@/modules/core/components/layouts/PageCard'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'CreateGroup',
  components: {
    PageCard,
    Multiselect
  },
  created() {
    this.$http
      .get('/api/groups/group_options', {
        handleErrors: true,
        params: {
          super_admin: true
        }
      })
      .then(res => {
        this.users = res.data.users
        this.group_types = res.data.group_types
      })
  },
  data() {
    return {
      group_types: [],
      users: [],
      selectedUser: {},
      group: {
        name: '',
        group_type_id: 4,
        company_type: '',
        user_ids: []
      }
    }
  },
  computed: {
    ...mapGetters(['companyTypes']),
    availableUsers() {
      return this.users.filter(user => user.available === true)
    },
    selectedUsers() {
      return this.users.filter(user => user.selected === true)
    },
    canCreate() {
      return this.group.name.trim() === '' || this.group.company_type === ''
    }
  },
  methods: {
    getUserDetail(user) {
      return user.email + ' - ' + user.first_name + ' ' + user.last_name
    },
    addUser() {
      this.users.forEach(user => {
        if (user.id === this.selectedUser.id) {
          user.available = false
          user.selected = true
          this.selectedUser = {}
        }
      })
    },
    removeUser() {
      this.users.forEach(user => {
        if (user.id === this.selectedUser.id) {
          user.available = true
          user.selected = false
          this.selectedUser = {}
        }
      })
    },
    selectUser(user) {
      this.selectedUser = user
    },
    updateuserIds() {
      this.users.map(user => {
        if (user.selected === true) {
          this.group.user_ids.push(user.id)
        }
      })
    },
    createGroup() {
      this.updateuserIds()
      this.$http
        .post('/api/groups', this.group, {
          handleErrors: true
        })
        .then(res => {
          Vue.toasted.show(res.data.status, {
            icon: 'user-circle',
            type: 'success'
          })
          router.push('/super_admin/all_groups')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.group-sub-card {
  height: 13rem;
  overflow-y: auto;
}

.list-group-item {
  border: none;
  cursor: pointer;

  &.selected {
    background-color: #f0f3f5;
  }
}

.white-text {
  color: white !important;
}
</style>
